import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { DtService } from '@services/dt.service';

import { ClientSettings, LocationSettings } from '@shared/settings.interface';
import { Cost } from '@shared/catalog.interface';

import { TodayState } from '@state/today.state';
import { UserState } from '@state/user.state';

@Injectable({
  providedIn: 'root',
})
export class AppState {
  public dateChangeSubject$ = new Subject<boolean>();
  public comparisonChangeSubject$ = new Subject<boolean>();
  public locationChangeSubject$ = new Subject<boolean>();
  public tabChangeSubject$ = new Subject<string>();
  public refreshLocationFilterSubject$ = new BehaviorSubject<boolean>(false);
  public startDayOfWeekChangeSubject$ = new Subject<number>();

  public costUpdate$ = new Subject<{ levelUpdated: string, cost: Cost[] }>();
  public costUpdateForce$ = new Subject<void>();
  public costUpdateSaved$ = new Subject<void>();

  public historyCompleteSubject$ = new BehaviorSubject<boolean>(true);

  public hideSplash$ = new Subject<void>();

  public clientID = '';
  public businessName = '';
  public locationID = '';
  public locationName = '';
  public rawLocations: LocationSettings[] = [];
  public rawLocationIDs: string[] = [];
  public rawLocationIndex = 0;
  public activeLocations: LocationSettings[] = [];
  public activeLocationIDs: string[] = [];
  public activeLocationNames: string[] = [];
  public activeLocationIndex = 0;
  public timezone = '';
  public startDayOfWeek = 1;
  public country = '';
  public stateProvince = '';
  public minDate = '';
  public minDateAsDate: Date | undefined;
  public maxDate = '';
  public maxDateAsDate: Date | undefined;
  public dayEndHour = 0;
  public urlParams = '';

  public menuOpen = false;
  public tabSelected = '';

  constructor(
    private dt: DtService,
    private todayState: TodayState,
    private userState: UserState,
  ) { }

  public resetState(): void {
    this.clientID = '';
    this.businessName = '';
    this.locationID = '';
    this.locationName = '';
    this.rawLocations = [];
    this.rawLocationIDs = [];
    this.rawLocationIndex = 0;
    this.activeLocations = [];
    this.activeLocationIDs = [];
    this.activeLocationNames = [];
    this.activeLocationIndex = 0;
    this.timezone = '';
    this.startDayOfWeek = 1;
    this.country = '';
    this.stateProvince = '';
    this.minDate = '';
    this.minDateAsDate = this.dt.now();
    this.maxDate = '';
    this.maxDateAsDate = this.dt.now();
    this.dayEndHour = 0;
    this.urlParams = '';
  }

  public updateLocationID(locationID: string, locations: LocationSettings[]): void {
    if (!locations?.length) return;

    const location = locations?.find(location => location.locationID === locationID) ?? locations[0];
    this.locationID = location.locationID;
    this.locationName = location.locationName ?? '';
    this.getCurrentLocationIndexes();
    this.userState.setPref('locationID', location.locationID);

    // Initialize todayState if needed
    if (!this.todayState.locationIDs.length) {
      this.todayState.updateLocationIDs([location.locationID], this.activeLocations);
      this.todayState.setTodayRange(this.dt.nowStr, this.dt.nowStr);
    }

    // Update local timezone for date functions
    this.timezone = location.timezone || 'America/New_York';
    this.dt.updateTimezone(location.timezone);

    this.country = location.country ?? '';
    this.stateProvince = location.stateProvince ?? '';
    // TODO: Add function to update country/state and refresh all relevant content (events)
    this.setMinMaxDates(location);
    this.dayEndHour = location.dayEndHour;
  }

  public updateLocations(rawSettings: ClientSettings, validatedSettings: ClientSettings): void {
    // Update business settings first
    this.businessName = rawSettings.businessName ?? '';
    this.startDayOfWeek = rawSettings.startDayOfWeek ?? 1;
    this.dt.updateStartDayOfWeek(this.startDayOfWeek);

    this.rawLocations = rawSettings.locations
      .filter(location => !this.userState.locationIDs.length ||
        this.userState.locationIDs.includes(location.locationID));
    this.activeLocations = validatedSettings.locations
      .filter(location => !this.userState.locationIDs.length ||
        this.userState.locationIDs.includes(location.locationID));
    const activeWithDataLocations = this.activeLocations.filter(location => location.minDate);

    this.rawLocationIDs = this.rawLocations.map(location => location.locationID);
    this.activeLocationIDs = this.activeLocations.map(location => location.locationID);
    this.activeLocationNames = this.activeLocations.map(location => location.locationName ?? '');
    const activeWithDataLocationIDs = activeWithDataLocations.map(location => location.locationID);

    let locationID = this.userState.getPref('locationID', this.activeLocationIDs[0]) as string;
    if (!activeWithDataLocationIDs.includes(locationID)) {
      locationID = activeWithDataLocationIDs[0] ?? this.activeLocationIDs[0] ?? this.activeLocationIDs[0] ??
        this.rawLocationIDs[0];
    }
    this.updateLocationID(locationID, this.activeLocations);
  }

  public refreshLocationFilter(): void {
    if (this.activeLocationIDs.length > 1) {
      this.refreshLocationFilterSubject$.next(true);
    }
  }

  private getCurrentLocationIndexes(): void {
    const rawIndex = this.rawLocationIDs.indexOf(this.locationID);
    this.rawLocationIndex = rawIndex !== -1 ? rawIndex : 0;

    const activeIndex = this.activeLocationIDs.indexOf(this.locationID);
    this.activeLocationIndex = activeIndex !== -1 ? activeIndex : 0;
  }

  public setMinMaxDates(location: LocationSettings): void {
    this.minDate = location?.minDate || this.dt.nowStr;
    this.minDateAsDate = this.dt.newDate(this.minDate + 'T12:00:00');
    this.maxDate = this.dt.maxDate;
    this.maxDateAsDate = this.dt.newDate(this.maxDate + 'T12:00:00');
  }
}
