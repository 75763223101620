export interface UserSettings {
  uid: string;
  clientID: string;
  name: string;
  email: string;
  restrictions: string[];
  locationIDs: string[];
  parentIDs: string[];
  betaUser: boolean;
  firstUse: string;
  uses: number;
  latestUse: string;
  latestVersion: string;
  prefs?: UserPreferences;
}

export interface PendingUser extends
  Omit<UserSettings, 'uid' | 'name' | 'firstUse' | 'uses' | 'latestUse' | 'latestVersion' | 'betaUser' | 'prefs'> {
  valid?: boolean;
  invitationSentDate?: Date;
}

export interface PendingUserWithID extends PendingUser {
  id: string;
}

export interface UserSetup extends PendingUserWithID {
  pending: boolean;
  isEditable: boolean;
  formattedInvitationSentDate: string;
}

export interface UserPreferences {
  authPersistence: string;
  comparisonDayCustomOffset: number;
  comparisonDayID: string;
  comparisonShowEvent: boolean;
  costType: string;
  demoPOS: string;
  groupByCategory: boolean;
  heatmapFilterAnimateBy: string;
  heatmapFilterDaysOfWeek: string;
  heatmapFilterGroupBy: string;
  heatmapFilterLocations: string;
  heatmapFilterMetric: string;
  heatmapFilterShowThru: string;
  heatmapFilterShowThruCustomDate: string;
  heatmapFilterTimeframe: string;
  heatmapFilterTimeframeCustomTimeframe: number;
  heatmapFilterTimeframeCustomUnits: string;
  heatmapFilterTimeOfDay: string;
  heatmapFilterTimeOfDayEnd: number;
  heatmapFilterTimeOfDayStart: number;
  hiddenChannels: string;
  includeInactiveCategories: boolean;
  includeInactiveItems: boolean;
  lastError: string;
  locationID: string;
  promptForGoogle: boolean;
  showDemoMessage: boolean;
  showHistoryStatus: string;
  showMigrationAlert: boolean;
  showRefreshMessage: boolean;
  showTrendLine: boolean;
  stackTrends: boolean;
  stack100: boolean;
  trendsFilterAggregation: string;
  trendsFilterAggregationMTD: number;
  trendsFilterAggregationQTD: number;
  trendsFilterAggregationYTD: number;
  trendsFilterComparison: string;
  trendsFilterDayOfWeek: number;
  trendsFilterEvent: string;
  trendsFilterGroupBy: string;
  trendsFilterLocations: string;
  trendsFilterMetric: string;
  trendsFilterQuantity: string;
  trendsFilterShowThru: string;
  trendsFilterShowThruCustomDate: string;
  trendsFilterTimeOfDay: string;
  trendsFilterTimeOfDayEnd: number;
  trendsFilterTimeOfDayStart: number;
  zoomFactor: number;
}

export interface SummaryRow {
  id: string;
  email: string;
  type: string;
  locations: string;
  pending: boolean;
  isEditable: boolean;
}

export interface UserPermissions {
  id: string,
  name: string,
  restricted: boolean,
}

export const permissions: UserPermissions[] = [
  { id: 'billing', name: 'Manage billing', restricted: true },
  { id: 'users', name: 'Manage users', restricted: true },
  { id: 'insights', name: 'Customize insights', restricted: false },
  { id: 'settings', name: 'Manage locations / connections', restricted: false },
  { id: 'catalog', name: 'Manage categories / products', restricted: false },
  { id: 'profitability', name: 'Manage profitability analysis', restricted: false },
  { id: 'download', name: 'Download data', restricted: false },
  { id: 'locations', name: 'Access all locations', restricted: false },
];

// Custom claims are user settings accessible to Firestore Rules
export interface CustomClaims {
  clientID: string;
}
